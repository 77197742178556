<template>
  <div class="divTable greenTable">
    <div class="divTableHeading divTableHeading-V-Table">
      <div class="divTableRow">
        <div class="divTableHead divTableHead--white">Month</div>
        <div class="divTableHead">Measured wind speed</div>
        <div class="divTableHead">Wind Direction</div>
        <div class="divTableHead">logarithmic</div>
        <div class="divTableHead">Hellmann coefficient</div>
      </div>
    </div>
    <div class="divTableBody">
      <div
        class="divTableRow divTableRow--V-Table"
        v-for="e in windspeedcalculator"
        v-bind:key="e.month"
      >
        <div class="divTableIDS">{{ e.month }}</div>
        <div class="divTableCell">
          {{ formatNum(e.measuredWindspeed) }}
        </div>
        <div class="divTableCell">
          {{ formatNum(e.windDirection) }}
        </div>
        <div class="divTableCell">
          {{ formatNum(e.logRoughnessLength) }}
        </div>
        <div class="divTableCell">
          {{ formatNum(e.hellmann) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, watch } from "vue";
import { formatNum } from "../helper/formatNumber";

export default {
  name: "WindSpeedCalculationTable",
  setup() {
    const store = useStore();
    const windspeedcalculator = ref([0, 0, 0, 0, 0, 0, 0, 0]);
    const calculation = computed(() => store.getters.calculation);
    function calculateRows() {
      for (let i = 0; i < 12; i++) {
        let months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        let month = months[i];
        windspeedcalculator.value[i] = {
          month: month,
          logRoughnessLength:
            Math.round(
              calculation.value.windspeedCalculator.logRoughnessLength[i] * 100
            ) / 100,
          windDirection:
            Math.round(
              calculation.value.windspeedCalculator.windDirection[i] * 100
            ) / 100,
          hellmann:
            Math.round(
              calculation.value.windspeedCalculator.hellmann[i] * 100
            ) / 100,
          measuredWindspeed:
            Math.round(
              calculation.value.windspeedCalculator.measuredWindspeed[i] * 100
            ) / 100,
        };
      }
    }
    watch(calculation, () => {
      if (calculation.value.airDensityCalculor) {
        calculateRows();
      }
    });
    if (calculation.value.airDensityCalculor) {
      calculateRows();
    }
    return {
      windspeedcalculator,
      formatNum,
    };
  },
};
</script>

<style></style>
