<template>
  <div class="chartWrapper">
    <apexchart class="pChart" :options="options" :series="series"></apexchart>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { formatNum } from "../helper/formatNumber";

export default {
  name: "MeasuredWindSpeedChart",
  setup() {
    const store = useStore();
    const calculation = computed(() => store.getters.calculation);
    const options = {
      stroke: {
        curve: "straight",
      },
      chart: {
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.1,
        },
        toolbar: {
          show: true,
          offsetX: -5,
          offsetY: 5,
        },
      },
      colors: ["#00f0e5", "#008764", "#00bae3"],
      title: {
        text: "Standard Power Curve",
        align: "left",
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.7,
        },
      },
      yaxis: {
        min: 0,
        labels: {
          formatter: function (value) {
            return formatNum(value);
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -10,
        offsetX: -5,
      },
    };
    const series = computed(() => [
      {
        name: "cp-value",
        data: calculation.value.windspeedCalculator?.standPCofTurbines.map(
          (e) => {
            return Math.round(e * 100) / 100;
          }
        ) || [0, 0, 0],
      },
    ]);
    return {
      series,
      options,
    };
  },
};
</script>

<style></style>
