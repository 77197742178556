<template>
  <div class="sectionContainer">
    <div class="section">
      <WindSpeedCalculationTable></WindSpeedCalculationTable>
    </div>
    <div class="grid_split">
      <MeasuredWindSpeedChart></MeasuredWindSpeedChart>
      <div class="chartWrapper">
        <CPValue></CPValue>
      </div>
    </div>
    <div class="windrose-section">
      <img :src="windRoseImg" v-if="windRoseImg" />
      <button
        class="button--primary"
        @click="getWindrose"
        :disabled="gettingWindrose || windRoseImg"
        v-if="!windRoseImg"
      >
        Get windrose
      </button>
      <div class="tooltipPosition">
        <button class="tooltipInfo" data-tooltip="I’m the tooltip text.">
          i
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import WindSpeedCalculationTable from "@/components/WindSpeedCalculationTable.vue";
import MeasuredWindSpeedChart from "@/components/MeasuredWindSpeedChart.vue";
import CPValue from "@/components/CPValue.vue";
const ServerURL = process.env.VUE_APP_ENV_SERVER || "";
import axios from "axios";
import { useStore } from "vuex";
import { computed, ref } from "vue";
export default {
  name: "WindspeedCalculation",

  components: {
    WindSpeedCalculationTable,
    MeasuredWindSpeedChart,
    CPValue,
  },
  setup() {
    const store = useStore();
    const calculation = computed(() => store.getters.calculation);
    const windRoseImg = ref(null);
    const gettingWindrose = ref(false);
    function getWindrose() {
      gettingWindrose.value = true;
      axios
        .post(ServerURL + "/getWindRose", {
          ws: calculation.value.windspeedCalculator.windRose.ws,
          wd: calculation.value.windspeedCalculator.windRose.wd,
        })
        .then((response) => response.data)
        .then((returnValue) => {
          gettingWindrose.value = false;
          windRoseImg.value = "data:image/jpeg;base64," + returnValue.windRose;
        })
        .catch((err) => {
          console.log(err);
          gettingWindrose.value = false;
        });
    }
    return {
      getWindrose,
      windRoseImg,
      gettingWindrose,
    };
  },
};
</script>

<style lang="scss" scoped></style>
